/**
* 8-point grid spacing variables
*
*/

:root {
  --pt025: 2px;
  --pt05: 4px;
  --pt075: 6px;
  --pt1: 8px;
  --pt1_5: 12px;
  --pt2: 16px;
  --pt2_5: 20px;
  --pt3: 24px;
  --pt4: 32px;
  --pt5: 40px;
  --pt6: 48px;
  --pt7: 56px;
  --pt8: 64px;
  --pt9: 72px;
  --pt10: 80px;
  --pt11: 88px;
  --pt12: 96px;
  --pt13: 104px;
  --pt14: 112px;
  --pt15: 120px;
  --pt16: 128px;

  --base_cols_per_card: 5.5;
  --cols_per_card: var(--base_cols_per_card);
  --container_width: 1400px;
}
