.dropdownMenu {
  gap: var(--pt2);
  display: flex;
  flex-direction: column;
}
.menuItem {
  width: fit-content;
}
.tagContainer {
  display: flex;
  gap: var(--pt1_5);
  overflow-x: scroll;
  white-space: nowrap;
  overflow-y: hidden;
  margin: 0 -16px var(--pt2);
  padding: var(--pt1) var(--pt2);
  padding-top: var(--pt2);
  border-bottom: 1px solid var(--iron);
}

.tagWrapper {
  display: grid;
}

.tagItem {
  box-shadow: none !important;
}
