.menu {
  padding: 0 var(--pt2) var(--pt2) var(--pt2);
  background-color: var(--blue);
  height: 100%;

  /* scroll in modal */
  overflow-y: auto;
}

/* passed to Top */

.top *,
.top *:after {
  color: var(--white);
  border-bottom-color: var(--white);
}

/* passed to Top */
.close svg path {
  stroke: var(--white);
}
/* passed to Top */
.back svg path {
  fill: var(--white);
  stroke: var(--white);
}

.menu ul {
  width: 100%;
}

.menu ul li {
  list-style: none;
  padding: var(--pt1) 0;
  cursor: pointer;
}

.hidden {
  display: none;
}

li.language {
  margin-top: var(--pt7);
}

/* Link component styles override */
.link {
  --underline-bottom-hover-color: var(--white);
  --underline-font-color: var(--white);
  --underline-font-hover-color: var(--white);
}

@media (min-width: 576px) {
  .menu {
    padding: 0 var(--pt6) var(--pt6) var(--pt6);
  }
}
