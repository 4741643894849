.WorkSlider {
  position: relative;
  margin-left: calc(var(--pt2) * -1);
  margin-right: calc(var(--pt2) * -1);
}
.skeleton {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.SlideWrapper {
  border-right: 1px solid var(--iron);
  height: auto;
}

.SlideWrapper:last-of-type {
  border-right: 0;
  padding-right: var(--pt1);
  margin-right: 0;
}

.SlideChildren > * {
  box-shadow: 1px 0 0 var(--iron);
}

.SlideChildren > *:last-child {
  box-shadow: none;
}
