.element {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.wrapper:focus-visible {
  z-index: 2;
}

.wrapper:hover {
  z-index: 3;
}

.wrapper {
  width: auto;

  --underline-bottom-hover-color: var(--blue);
  --underline-bottom-color: var(--iron);
  --underline-top-hover-color: var(--blue);
  --underline-top-color: var(--iron);
  --underline-font-color: var(--mine-shaft);
  --underline-font-hover-color: var(--mine-shaft);
  margin-bottom: calc(var(--underline-height) * -1);
  margin-inline: calc(var(--pt2) * -1);
}

.header_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--pt2);
}

.link_on_year {
  --underline-bottom-color: var(--blue);
  --underline-bottom-hover-color: var(--blue);
  --underline-font-hover-color: var(--blue);
}

.firstelement {
  padding-left: var(--pt2);
  display: inline;
  width: 20%;
}

.textbox {
  display: block;
  width: 100%;
}

.textbox span:first-of-type {
  font-family: var(--body-font-semibold);
  display: block;
}

.textbox span {
  justify-self: start;
  display: none;
}

.expandIcon {
  padding-inline: 0 var(--pt1) 0 0;
  display: flex;
  align-items: center;
}

.skeleton {
  pointer-events: none;
}

.open {
  background-color: var(--concrete);
}

.content {
  padding-top: var(--pt2);
  /*max-height: 0;*/
}

@media (min-width: 992px) {
  .textbox span {
    display: block;
  }

  .firstelement {
    width: 10%;
  }

  .textbox {
    display: grid;
    grid-template-columns: 5fr 7fr 6fr;
    align-items: center;
    width: 100%;
  }

  .header_content {
    padding: var(--pt1) var(--pt1) var(--pt1) 0;
  }

  .wrapper {
    margin-inline: 0;
  }
}
