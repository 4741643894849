.arrow_styling {
  --arrow__background_color: var(--white);
  --arrow__fill_color: var(--blue);
  --arrow__background_color_hover: var(--blue);
  --arrow__fill_color_hover: var(--blue);
  --arrow__box_shadow: 0;
}

.arrow_hidden {
  /* Hide completely from DOM - not tabbable */
  display: none;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--pt4);
}

.mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--pt4);
}

.pagination button:not(.arrow_styling) {
  font-size: var(--pt2);
  line-height: var(--pt3);
  font-family: var(--body-font-medium);
  font-style: normal;
  user-select: none;
  cursor: pointer;
  background-color: transparent;
}

.pagination button:focus,
.pagination button:hover {
  background-color: var(--white);
  outline: 1px solid var(--blue);
  border: 0;
}

.pagination button:not(.arrow_styling):focus:not(:focus-visible) {
  outline: 0;
  border: 0;
}

.pagination button.selected {
  color: white;
  background-color: var(--blue);
}

.arrowPlaceholder {
  width: var(--pt4);
}

@media (min-width: 768px) {
  .desktop {
    display: flex;
    gap: var(--pt1);
  }
}
