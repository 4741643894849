.login {
  padding: 0 var(--pt2) var(--pt2);
}

.login .title {
  padding: var(--pt1) 0;
}

.login mark {
  background-color: transparent;
  font-family: var(--body-font-semibold);
}

@media (min-width: 576px) {
  .login {
    padding: 0 var(--pt6) var(--pt3) var(--pt6);
  }
}

.chooseLoginType {
  padding-bottom: var(--pt4);
}
@media (min-width: 415px) {
  .chooseLoginType {
    display: none;
  }
}
