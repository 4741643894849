.login {
  /* height: var(--pt3); */
  height: 30px;

  overflow: hidden;
  /* transform: translate(0, -6px); */
  transform: translate(0, -8px);
}

.icon {
  /* transform: translate(0, 6px); */
  transform: translate(0, 8px);
}

.head {
  /* width: var(--pt1);
  height: var(--pt1); */
  width: 10px;
  height: 10px;

  border: 2px solid var(--mine-shaft);
  border-radius: 50%;
  margin: auto;
}

.bodyWrap {
  /* height: 10px; */
  height: 12px;

  overflow: hidden;
}

.body {
  /* width: var(--pt2);
  height: var(--pt2); */
  width: 18px;
  height: 18px;

  border: 2px solid var(--mine-shaft);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  margin: auto;
  position: relative;
}

.border {
  width: var(--pt2);
  height: 2px;
  background-color: var(--mine-shaft);
  position: absolute;
  bottom: 4px;
  left: -1px;
}
@media (hover: hover) {
  .trigger:hover .icon,
  .trigger:focus .icon {
    animation: jumpUp 0.4s cubic-bezier(0.3, 1, 0.32, 1.3);
  }

  .trigger:hover .head,
  .trigger:focus .head {
    animation: jumpHead 0.4s cubic-bezier(0.3, 1, 0.32, 1.3);
  }
}
@keyframes jumpUp {
  from {
    transform: translate(0, 100%);
  }
  to {
    /* transform: translate(0, 6px); */
    transform: translate(0, 8px);
  }
}

@keyframes jumpHead {
  from {
    transform: translate(0, 8px);
  }
  to {
    transform: translate(0, 0);
  }
}
