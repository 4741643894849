.warning {
  background-color: var(--warning);
}

.error {
  background-color: var(--error);
}

.info {
  background-color: var(--info-light);
}

.success {
  background-color: var(--success);
}

.hidden {
  display: none;
}

div.notification {
  text-align: center;
  padding-top: var(--pt2);
  border-bottom: 1px solid var(--mine-shaft);
}

/* Small devices (landscape phones, 576px and down) */
@media (max-width: 576px) {
  div.notification {
    text-align: left;
  }
}

div.notification i {
  float: right;
  padding: var(--pt1);
}

div.notification .cancelicon:hover {
  cursor: pointer;
}

@media print {
  .info {
    display: none;
  }
}
