.dropdown_inputs_wrapper {
  padding-top: var(--pt4);
}

.dropdownTitle {
  display: flex;
  align-items: center;
}

/* TODO: Comment in when worktypes are ready */
/*.subTitle {*/
/*  font-style: italic;*/
/*  margin-left: var(--pt2);*/
/*}*/

.flex_wrapper {
  display: flex;
  gap: var(--pt1);
  padding-top: var(--pt2);
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: var(--pt3);
}

.dropdown_with_title {
  display: flex;
  flex-direction: column;
  row-gap: var(--pt05);
}

@media (max-width: 640px) {
  .dropdownTitle {
    display: none;
  }
}

@media (max-width: 768px) {
  .dropdown_with_title {
    width: 48%;
  }
}

.dropdownTitleContainer {
  display: flex;
}

.tooltip {
  border-bottom: none !important;
  display: flex;
  gap: var(--pt1);
  align-items: flex-start !important;
  cursor: pointer;
}
.showMoreButtonContainer {
  height: var(--pt5);
  align-self: flex-end;
  display: flex;
  align-items: center;
}
.showMoreButton {
  padding: 0 var(--pt2);
  gap: var(--pt1);
  display: flex;
  align-items: center;
}
