.loanerform {
  max-width: 600px;
  padding-inline: var(--pt2);
  height: 100%;
  /* scroll in modal */
  overflow-y: auto;
}
.loanerform input {
  margin-top: var(--pt1);
}

.loanerform .fields {
  padding-top: var(--pt1);
  padding-bottom: var(--pt4);
  position: relative;
}

.loanerform .field {
  padding-top: var(--pt3);
}

.loanerform button {
  margin-top: var(--pt3);
}
.loanerform .loginbutton {
  margin-top: var(--pt5);
}
.loanerform p,
.loanerform label {
  margin-top: var(--pt3);
}
.loanerform h4 {
  margin-top: var(--pt5);
}

.loanerform p.explain {
  margin-top: var(--pt1);
}

.loanerform .error {
  position: absolute;
  bottom: 0px;
  color: var(--error);
}

@media (min-width: 576px) {
  .loanerform {
    padding: var(--pt2) var(--pt6) var(--pt4);
  }
}

.checkBoxSpan {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: var(--pt2);
  margin-bottom: var(--pt6);
}

.checkBoxSpan > label,
.checkBoxSpan > p {
  margin-top: 0;
  margin-right: var(--pt1);
  letter-spacing: 0.5px;
}
