.top {
  padding: var(--pt3) var(--pt2) var(--pt2);
}

.options {
  padding: 0 var(--pt2) var(--pt2) var(--pt2);
  height: 100%;

  /* scroll in modal */
  overflow-y: auto;
}

.list {
  list-style: none;
  margin-bottom: var(--pt2);
}

.linkWrapperDisabled {
  cursor: not-allowed;
}

.link[disabled] {
  color: var(--dove);
}

.item {
  padding-bottom: var(--pt4);
}

@media (min-width: 576px) {
  .options {
    padding: 0 var(--pt5) var(--pt3) var(--pt6);
  }
  .top {
    padding: var(--pt5) var(--pt6) var(--pt3);
  }
}
