/**
* breakpoints
*
*/

:root {
  --xs: 0;
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;
  --xxl: 1400px;
}
