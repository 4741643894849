.header {
  padding-top: var(--pt2);
  padding-bottom: var(--pt2);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
}

.header > div {
  align-items: center;
}

.right span {
  display: inline-flex;
  align-items: center;
}

.menu {
  display: inline-flex;
  flex-direction: row-reverse;
}

.menu i {
  margin-left: var(--pt2);
}

.right {
  display: flex;
}

.right span {
  margin-left: auto;
}

/* Logo */
.row > div {
  /* align-self: flex-start; */
}
.inputOuterWrapper {
  position: relative;
}
.inputwrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 0.35s;
  transform: translateY(12px);
  font-size: 40px !important;
  line-height: 52px !important;
  z-index: 2;
  margin: 0 var(--pt2);
}
.inputwrapper h1 {
  font-size: 40px !important;
  line-height: 52px !important;
  transform-origin: left;
  transition: transform 0.35s;
  transform: scale(0.7);

  /* hide on mobile */
  display: none;
}
.expanded .inputwrapper {
  transform: translateY(var(--pt12));
}
.expanded .inputwrapper h1 {
  display: inline-block;
  transform: scale(1);
}
.input {
  margin-top: var(--pt3);
}

@media (min-width: 992px) {
  .header {
    padding-top: var(--pt4);
    padding-bottom: var(--pt4);
    box-shadow: none;
  }

  .inputwrapper {
    transform: translateY(-34px);
  }

  .inputwrapper h1 {
    display: inline-block;
  }

  .expanded .inputwrapper {
    transform: translateY(152px);
  }
}
