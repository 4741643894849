.user {
  /* padding: var(--pt2); */
}

/* User */

.name {
  padding: var(--pt2) 0 0 0;
}

.email {
  padding: var(--pt2) 0 0 0;
}

.input {
  margin: var(--pt1) 0 0 0;
}

.emailMessage {
  padding: var(--pt1) 0 0 0;
  display: flex;
  align-items: center;
  justify-content: left;
}

.tooltip {
  position: relative;
  margin-left: var(--pt05);
}

.textinline {
  display: inline;
}

.skeleton .emailMessage {
  padding: var(--pt1) 0;
}

.userStatusLink {
  display: inline;
}

@media (min-width: 576px) {
  .user {
    /* padding: var(--pt3) var(--pt6); */
  }

  .emailMessage.valid {
    display: none;
  }
}
