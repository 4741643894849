.search {
  /* width: 18px;*/
  /* height: 24px; */
  width: 22px;
  height: 30px;
  overflow: hidden;
  /* transform: translate(-1px, -6px); */
  transform: translate(-1px, -8px);
}

.icon {
  position: relative;
  /* width: 16px; */
  /* height: 18px; */
  width: 22px;
  height: 22px;
  /* transform: translate(1px, 6px); */
  transform: translate(1px, 8px);
}

.lens {
  /* width: 13px; */
  /* height: 13px; */
  width: 16px;
  height: 16px;
  border: 2px solid var(--mine-shaft);
  border-radius: 50%;
  position: relative;
}

.handle {
  position: absolute;
  /* width: 8px; */
  width: 10px;
  height: 2px;
  background-color: var(--mine-shaft);
  /* transform: translate(5px, 3px) rotate(45deg); */
  transform: translate(7px, 3px) rotate(45deg);
  right: 0;
  bottom: 0;
}

@media (hover: hover) {
  .trigger:hover .lens,
  .trigger:focus .lens {
    animation: dropDown 0.4s cubic-bezier(0.3, 1, 0.32, 1.3);
  }

  .trigger:hover .handle,
  .trigger:focus .handle {
    animation: growHandle 0.4s cubic-bezier(0.3, 1, 0.32, 1.3);
  }
}

@keyframes dropDown {
  0% {
    transform: translate(0, -150%);
  }
  50% {
    transform: translate(0, 4px) scale(1.1, 0.9);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

@keyframes growHandle {
  0%,
  75% {
    /* transform: translate(5px, 3px) rotate(45deg) scale(0); */
    transform: translate(7px, 3px) rotate(45deg) scale(0);
  }
  100% {
    /* transform: translate(5px, 3px) rotate(45deg) scale(1); */
    transform: translate(7px, 3px) rotate(45deg) scale(1);
  }
}
