.options {
  padding: 0 var(--pt2) var(--pt2) var(--pt2);
  height: 100%;

  /* scroll in modal */
  overflow-y: auto;
}

.list {
  list-style: none;
}

.item {
  padding-bottom: var(--pt4);
}

@media (min-width: 576px) {
  .options {
    padding: 0 var(--pt6) var(--pt6) var(--pt6);
  }
}
