.dropdownwrap {
  height: var(--pt5);
}
.dropdownwrap:hover,
.dropdownwrap:focus {
  outline: 0;
  cursor: pointer;
}

.dropdownitem {
  display: block;
  border-bottom: 1px solid var(--mine-shaft);
  padding: var(--pt1) var(--pt2);
  text-decoration: none;
  color: var(--mine-shaft);
  text-align: inherit;
  white-space: nowrap;
}

.bigFont {
}

.dropdownitem a {
  text-decoration: none;
}

.dropdownitem:hover,
.dropdownitem:focus {
  background: linear-gradient(
    to right,
    var(--blue) var(--pt05),
    var(--concrete) var(--pt05)
  );
}

.dropdownwrap :global(.dropdown-toggle::after) {
  display: none;
}

.dropdownmenu {
  border: 1px solid var(--blue);
  background-color: var(--white);
  z-index: 5;
  /* bootstrap default styles override */
  border-radius: 0;
  padding: 0;
}

.dropdowntoggle:is(:focus-visible, :hover) {
  box-shadow: inset 0 0 0 2px var(--blue);
}

.dropdowntoggle {
  position: relative;
  height: var(--pt5);
  padding-left: var(--pt2);
  box-shadow: inset 0 0 0 1px var(--dove);
  background-color: var(--white);
  min-width: 164px;
  text-align: left;
  color: black;
}

.dropdowntoggle[aria-expanded="true"] .dropdownicon img {
  transform: rotate(180deg);
}

.dropdownicon {
  transform: rotate(90deg);
  position: absolute;
  right: var(--pt2);
  top: 0;
  bottom: 0;
  margin: auto;
}

.dropdownicon img {
  transition: transform 0.2s linear;
}

.selectedItem {
  background-color: var(--blue);
  color: var(--white);
}

@media (max-width: 768px) {
  .bigFont {
    font-size: 17px;
  }

  .dropdowntoggle {
    min-width: 180px;
  }
}
