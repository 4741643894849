.label {
  display: block;
  margin-bottom: var(--pt2);
}

.formatted {
  border: 1px rgb(130, 130, 130) solid !important;
  margin-bottom: var(--pt05);
}
.formatted.focused {
  border: 1px solid var(--blue) !important;
  outline: 1px solid var(--blue) !important;
}

.formatted :global(.cql-error) {
  text-decoration: #ba4d57 wavy underline;
  text-underline-offset: 5px;
}
.formatted :global(.token-type_FIELD) {
  color: #1e874b;
}
.formatted :global(.token-type_ASSIGNMENT),
.formatted :global(.token-type_WITHIN),
.formatted :global(.token-type_PARENTHESIS_BEGIN),
.formatted :global(.token-type_PARENTHESIS_END),
.formatted :global(.token-type_RANGE_OPERATOR),
.formatted :global(.token-type_OPERATOR) {
  color: #3333ff;
}
.formatted :global(.token-type_LITERAL) {
  color: #212121;
}
