.container {
  width: 100%;
  padding-bottom: var(--pt7);
  padding-top: var(--pt2);
}

.background {
  background-color: var(--concrete);
}

.topContainer {
  display: flex;
  align-items: start;
  margin-bottom: var(--pt4);
}

.buttonRow {
  padding-top: var(--pt4);
}

.button {
  width: 156px;
}

.button_group {
  display: flex;
  gap: var(--pt3);
  align-items: center;
}

.buttonContainer {
  display: flex;
  gap: var(--pt3);
  align-self: end;
  justify-content: end;
}

.closeContainer {
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: end;
  transition: 250ms;
  position: relative;
  top: 2px;
}

.helpLink {
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
  margin-left: auto;
}

.asblock {
  display: block;
}

/*
NOTES
463- Brug mobil-layout
464+ Tekst til “Ryd” og “Hjælp” skal bruge desktopversionen.
640+ Index-dropdowns og søgefelt skal ligge på samme linje.
768+ Avanceret søgning + links + luk placeres som på desktop
992+ Brug desktop-layout med ekstra (tom) spalte i venstre side og synlig topbar med simpleSearch, log ind etc.
 */
/* 640 and down */
@media (max-width: 768px) {
  .buttonContainer {
    justify-content: start;
  }

  .closeContainer {
    position: absolute;
    right: var(--pt2);
    top: var(--pt3);
  }
}

/* 330px down (very small devices) */
@media (max-width: 330px) {
  .closeContainer {
    position: unset;
  }
}

.hideCloseContainer {
  visibility: hidden;
  transition: 250ms;
}
