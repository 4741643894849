.upper_first:first-letter {
  text-transform: capitalize;
}

.menuButton {
  background-color: var(--white);
  padding-block: var(--pt2);
  padding-left: var(--pt2);
  padding-right: var(--pt5);
  outline: 1px solid var(--gray);
  min-width: 125px;
  height: 100%;
  display: flex;
  gap: var(--pt1);
  align-content: center;
  justify-content: space-between;
  color: black;
}

.menuButton:hover {
  cursor: pointer;
}

.menuButton:is(:focus-visible, :hover) {
  outline: 2px solid var(--blue);
}

.menuButton[aria-expanded="true"] .icon_open {
  transform: rotate(180deg);
}

.menuButton__text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon_area {
  display: flex;
  flex-wrap: wrap;
  width: 20px;
  height: 20px;
  justify-content: center;
  transition: transform 300ms ease;
  align-content: center;
  position: absolute;

  right: var(--pt2);
  bottom: calc(var(--pt1) + var(--pt025));
}

.icon {
  transition: transform 0.3s ease-in-out;
}

.select_wrapper {
  display: flex;
  align-items: center;
  gap: var(--pt1);
}

.single_range {
  display: flex;

  height: var(--pt5);
  width: var(--pt11);

  font-size: 14px;
  line-height: 22px;
}

.dash {
  padding-bottom: var(--pt1);
}

.toggler_content {
  display: flex;
  gap: var(--pt075);
}

.label_count {
  color: var(--white);
  border: 1px solid var(--blue);
  padding-inline: var(--pt075);
  background-color: blue;
}
