.order {
  /* scroll in modal */
  display: flex;
  flex-direction: column;
}

/* .top is passed as className prop to Top component */
.top {
  padding: var(--pt3) var(--pt2) var(--pt2) var(--pt2);
}

.pickup,
.user,
.action {
  padding: var(--pt2);
}

.action {
  margin-top: auto;
}

.pickup .title {
  padding: 0 var(--pt2) 0 0;
}

/* Library */
.library {
  padding: var(--pt2) 0 var(--pt1) 0;
  display: flex;
  justify-content: space-between;
}

.library > p {
  padding-right: var(--pt2);
}

/* User */

.name {
  padding: var(--pt2) 0 0 0;
}

.email {
  padding: var(--pt2) 0 0 0;
}

.input {
  margin: var(--pt2) 0 0 0;
}

.emailMessage {
  padding: var(--pt1) 0 0 0;
}

.tooltip {
  position: relative;
  top: var(--pt05);
  margin-left: var(--pt1);
}

.textinline {
  display: inline;
}

.skeleton .top,
.skeleton > article {
  padding: var(--pt2) var(--pt2);
}

.skeleton .emailMessage {
  padding: var(--pt1) 0;
}

.invalid-pickup {
  margin: var(--pt05) 0 0 0;
}
.invalid {
  color: var(--error);
}

.invalidInput {
  border: 1px solid var(--error);
}

.userStatusLink {
  display: inline;
}

/* action */

.action .message {
  padding: 0 0 var(--pt2) 0;
}

.action .message.invalid {
  color: var(--error);
}

.link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.link .fullLink {
  display: none;
}

.arrow {
  margin: 0 0 0 var(--pt1);
}

@media (min-width: 576px) {
  .skeleton .top,
  .skeleton > article {
    padding: var(--pt3) var(--pt6);
  }
  .top {
    padding: var(--pt5) var(--pt6) var(--pt3) var(--pt6);
  }

  .pickup,
  .user,
  .action {
    padding: var(--pt3) var(--pt6);
  }

  .link .fullLink {
    display: block;
  }

  .link .shortLink {
    display: none;
  }

  .emailMessage.valid {
    display: none;
  }
}
