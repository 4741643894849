/* Action button */

.receipt {
  padding: var(--pt6) var(--pt2);

  height: 100%;

  /* scroll in modal */
  overflow-y: auto;
}

@media screen and (min-width: 576px) {
  .receipt {
    padding-inline: var(--pt9);
  }
}

.container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.wrap {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
}

/* loader */

.progress {
  transition: opacity 0.3s linear;
}

.loader {
  margin: auto;
  width: 50%;
}

.receipt.ordered .container .progress,
.receipt.failed .container .progress {
  opacity: 0;
}

/* result */

.result {
  position: relative;
  transition: 0.5s ease-out;
  transition-delay: 0.3s;

  top: 10%;
}

.receipt.ordered .container .result,
.receipt.failed .container .result {
  top: 0;
}

/* Success */

.success,
.error {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: opacity 0.75s ease-in;
  transition-delay: 0.3s;

  opacity: 0;
}

.receipt.ordered .container .result .success,
.receipt.failed .container .result .error {
  opacity: 1;
  z-index: 1;
}

.success .check {
  height: var(--pt9);
  width: var(--pt9);
  border-radius: 50%;
  border: 1px solid var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
}

.success .title,
.success .ornament,
.success .message {
  padding: var(--pt3) 0 0 0;
  text-align: center;
}

.success .message {
  text-align: center;
}

.redirect {
  margin-top: var(--pt12);
  width: 99%;
}

.close {
  margin-top: var(--pt2);
}

/* error */

.error {
  display: flex;
}

.error > h2 {
  padding-bottom: var(--pt2);
}
