.receipt {
  padding: var(--pt6) var(--pt2);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.errorReceipt {
  padding-top: var(--pt15);
  padding-bottom: var(--pt10);
}

.check {
  height: var(--pt9);
  width: var(--pt9);
  border-radius: 50%;
  border: 1px solid var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  padding-top: var(--pt3);
  text-align: center;
}

.ornament {
  padding-top: var(--pt3);
  margin-bottom: var(--pt3);
}

.successMessage {
  margin-bottom: var(--pt1);
}

.message {
  text-align: center;
}

.errorMessage {
  margin: var(--pt5) 0;
  color: var(--error_temp);
}

.redirect {
  width: 99%;
  margin-top: var(--pt12);
}

.close {
  margin-top: var(--pt2);
}

.materialList {
  width: 100%;
  margin-bottom: var(--pt8);
}

@media screen and (min-width: 576px) {
  .receipt {
    padding-inline: var(--pt9);
  }

  .materialList {
    width: calc(100% + 160px);
  }
}
