.display_flex {
  height: var(--pt6);
  width: fit-content;
  display: flex;
  align-items: center;
  gap: var(--pt1);
}

@media (min-width: 992px) {
  .display_flex {
    padding-top: var(--pt1);
  }
}

.defaultLogo_Blue {
  color: var(--blue);
}

.defaultLogo_White {
  color: var(--white);
}

.defaultLogo_Blue *[data-text="true"] {
  color: var(--mine-shaft);
}
