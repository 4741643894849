.action {
  position: relative;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  color: var(--mine-shaft);
  text-decoration: none;

  white-space: nowrap;
}

.wrap {
  position: relative;
}

.action > div hr {
  display: none;
}

.action p {
  /* Adjust lineheight to pixel perfect align icons in height */
  line-height: 14px;
  margin-top: 4px;
}

.badge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
}

/* custom animationline */
.action:focus-visible hr:last-child,
.action:hover hr:last-child {
  display: none;
}

.action:focus-visible hr,
.action:hover hr {
  transform: scale(1);
}
/*  */

.action:focus-visible p {
  color: var(--blue);
}

@media (min-width: 992px) {
  .action div hr:first-of-type {
    display: block;
  }
}
