.icon {
  display: inline-block;
}

/* auto-scale child*/

.icon img,
.icon svg {
  width: 100%;
  height: auto;
}

.icon.autoH img,
.icon.autoH svg {
  width: 100%;
  height: auto;
}

.icon.autoW img,
.icon.autoW svg {
  width: auto;
  height: 100%;
}

/* Shape */

.round {
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.icon.round img {
  width: auto;
  height: 40%;
}

.icon.round svg {
  width: auto;
  height: 50%;
}

/* 
* disabled
*
*/

.disabled {
  pointer-events: none;
  cursor: default;
}

/* 
* Skeleton
*
*/

.skeleton {
  position: relative;
  overflow: hidden;
  /* Remove blue background-color */
  background-color: transparent !important;
}
