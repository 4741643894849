*:focus {
  outline: unset;
}

/* react-bootstrap-v2 fixes */

/* fill up remaining col space */
.row > * {
  min-width: 0px;
}

.dropdown-toggle::after {
  display: none;
}
