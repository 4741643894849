/* Skeleton */
.skeleton {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  cursor: default;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.lines {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.line {
  width: 100%;
  height: 100%;

  position: relative;

  overflow: hidden;

  background-color: var(--skeleton-bg-color);
  transition-duration: 1.5s;
  transition-property: background-color, background;

  margin: 0 0 var(--pt05) 0;
}

.lines .line:last-child {
  margin: 0;
  width: 75%;
}

.lines .line:first-child {
  width: 100%;
}

.skeleton .lines .line > * {
  opacity: 0;
}

.line::before {
  z-index: 2;
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(
    to right,
    transparent 0%,
    var(--skeleton-gradient-color) 50%,
    transparent 100%
  );
  animation: load 1.5s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

/**
* Keyframes 
* 
*/

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
