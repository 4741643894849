/**
* Colors 
*
* Colornames from:
* https://chir.ag/projects/name-that-color
*
*/

:root {
  /* Primary */
  --mine-shaft: #212121; /* Primary txt color */
  --blue: #3333ff; /* Interaction color, links, buttons, ... */

  /* Secondary */
  --concrete: #f2f2f2; /* Default bg color */

  --pippin: #ffe7e0; /* default */
  --chardon: #fff3f0; /* light */
  --bone: #e5c7bd; /* dark */

  --parchment: #f4efdd; /* default */
  --merino: #f9f7ee; /* light */
  --chamois: #eed9b0; /* dark */

  --jagged-ice: #d0ebee; /* default */
  --black-squeeze: #e7f5f6; /* light */
  --ziggurat: #b7dee2; /* dark */

  /* Greys (Tones) */
  --white: #ffffff;
  --iron: #d6d6d7;
  --gray: #828282;
  --dove: #6e6e6e;

  /* Alerts */
  --error: #d1414e;
  --error-light: #fab8ba;

  /* Alerts */
  --error_temp: #ba4d57;
  --error-light_temp: #fff3f0;

  --warning: #fbd85b;
  --warning-light: #fbeaad;

  --info: #3333ff;
  --info-light: #d0e5f9;

  --success: #1c7d45;
  --success-light: #e9f7ef;

  --transparent: #ff000000;
  --link-background: rgba(0, 0, 0, 0.1);
  --link-background-light: rgba(255, 255, 255, 0.7);

  --feedback-yellow-warning-background: #fbeaad;
  --feedback-yellow-warning-background-light: #fdf5d6;
  /* skeleton */
  --skeleton-bg-color: var(--concrete);
  --skeleton-gradient-color: #e8e8e8;

  /* Underline defaults */
  --underline-font-color: var(--mine-shaft);
  --underline-font-hover-color: var(--blue);

  --underline-top-color: var(--blue);
  --underline-top-hover-color: var(--blue);
  --underline-bottom-color: var(--blue);
  --underline-bottom-hover-color: var(--blue);
  --underline-middle-color: var(--transparent);
  --underline-middle-hover-color: var(--transparent);
}
