.withLoanerInfoButton {
  width: 100%;
  margin-bottom: var(--pt3);
}

@media (min-width: 415px) {
  .withLoanerInfoButton {
    display: none;
  }
}
