.title {
  padding-bottom: var(--pt3);
}

/* has divider class */
hr.divider {
  width: 50%;
  height: 1px;
  margin: 0 0 var(--pt2) 0;
  border: none;
  background-color: var(--iron);
}

.content hr.divider {
  width: 100%;
  display: none;
}

.background hr.divider {
  display: none !important;
}

.rightSideTitle {
  display: flex;
  align-items: baseline;
  justify-content: end;
}
@media (min-width: 992px) {
  .title {
    padding-bottom: 0;
  }

  .title hr.divider {
    width: 100%;
  }

  .content hr.divider {
    display: block;
  }
}
