.history {
  height: var(--pt5);
  display: flex;
  align-items: center;
}

.wrap {
  width: var(--pt5);
  height: var(--pt5);
  border-radius: 50%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  width: calc(100% - 40px);

  display: flex;
  flex-direction: column;
  padding-left: var(--pt2);

  overflow: hidden;
}

.value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.type {
}
