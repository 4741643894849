.feedbackwrap {
  background-color: var(--blue);
  position: fixed;
  right: 0;
  top: 70%;
  display: flex;
  align-items: center;
  height: 115px;
  max-width: 225px;
  z-index: 101;
}

.textrotate {
  cursor: pointer;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  color: var(--white);
  padding: 8px 3px 15px 3px;
  width: 30px;
}

@media print {
  .feedbackwrap {
    display: none;
  }
}
