.pickup {
  padding: 0 var(--pt2) var(--pt2) var(--pt2);
}

.input {
  margin: var(--pt2) 0;
}

.search .title {
  padding: var(--pt1) 0;
}

.pickup mark {
  background-color: transparent;
  font-family: var(--body-font-semibold);
}

.loadingText {
  margin-top: var(--pt4);
}

.pickup li {
  list-style-type: none;
  margin-bottom: var(--pt05);
}

.pickupNotAllowedTitle {
  margin-top: var(--pt6);
  margin-bottom: var(--pt1);
}

.orderPossibleGroup {
  margin-top: var(--pt4);
}

.alternativeMatch {
  /* color: var(--gray); */
}

.alternativeMatch mark {
  /* font-family: var(--body-font); */
  /* color: var(--gray); */
}

.disabled,
.disabled mark,
.disabled .alternativeMatch {
  color: black !important;
}

.squeeze {
  padding-top: var(--pt1);
  padding-bottom: var(--pt1);
}

.pickupSelectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: var(--pt1);
}

.collapseWrapper {
  width: 100%;
}

.showPickupTitle {
  padding-bottom: var(--pt2);
}

.agencySelectionLink {
  display: flex;
  flex-direction: row;
}

.agencySelectionLink[aria-expanded="true"] .chevron img {
  transform: rotate(180deg);
}

.chevron {
  margin-left: var(--pt1);
  align-items: center;
}

.chevron img {
  transition: transform 0.2s linear;
}

@media (min-width: 576px) {
  .pickup {
    padding: 0 var(--pt6) var(--pt3) var(--pt6);
  }
}
