.flex_row {
  isolation: isolate;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.flex_arrow {
  align-self: center;
  display: none;
}

.flex_arrow.flex_arrow_left {
  margin-right: var(--pt5);
  margin-left: calc(var(--pt5) * -2);
}

.flex_arrow.flex_arrow_right {
  margin-left: var(--pt5);
  margin-right: calc(var(--pt5) * -2);
  transform: translateX(calc(var(--pt5) * -2 - var(--pt2)));
}

.flex_box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;

  padding-bottom: var(--pt2);

  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}

.flex_box > *:first-child {
  margin-left: calc(var(--pt2) * 1);
}

.flex_box > *:last-child {
  margin-right: calc(var(--pt2) * 1);
}

/* Reset scrollbar */
.flex_box::-webkit-scrollbar {
  width: 10px;
  height: var(--pt05);
  display: none;
}

.flex_box::-webkit-scrollbar-track {
  background: var(--concrete);
}

.flex_box::-webkit-scrollbar-thumb {
  background: var(--iron);
}

.flex_box::-webkit-scrollbar-thumb:hover {
  background: var(--gray);
}

@media (min-width: 992px) {
  .flex_arrow {
    display: initial;
  }

  .flex_row {
    margin-inline: 0;
  }

  .flex_box > *:first-child {
    margin-left: 0;
  }

  .flex_box > *:last-child {
    margin-right: 0;
  }
}

@media (min-width: 1550px) {
  .flex_arrow.flex_arrow_right {
    transform: none;
  }
}
