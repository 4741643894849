.manifestionlistContainer {
}

.manifestionlist {
  list-style-type: none;
  border-top: 1px solid var(--iron);
  border-bottom: 1px solid var(--iron);
  margin-bottom: var(--pt2);
}

.manifestionlistItem {
  display: flex;
  flex-wrap: wrap;
  padding: var(--pt1) var(--pt2) var(--pt1) var(--pt2);
  gap: var(--pt1);
}

.title {
  font-family: var(--body-font-semibold);
}

.manifestionlistItem p {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.manifestionlist li p:first-child {
  min-width: 50%;
}

.playingTime {
  text-align: right;
}

.manifestionlist li:nth-child(odd) {
  background: var(--concrete);
}

.manifestionlist li:nth-child(even) {
  background: none;
}

.contributors {
  color: var(--gray);
  margin-left: var(--pt1);
}

.arrowchanges {
  padding-left: var(--pt2);
}

p.breakOrder_playingTime {
  order: 1;
}

p.breakOrder_creator {
  order: 2;
  flex-basis: 100%;
}

@media (max-width: 576px) {
  .manifestionlist li {
    padding: var(--pt1) var(--pt2) var(--pt1) var(--pt2);
  }

  p.playingTime {
    order: 1;
  }

  p.creator {
    order: 2;
    flex-basis: 100%;
  }
}

@media (min-width: 992px) {
  .manifestionlist li p {
    display: inline;
  }
}
