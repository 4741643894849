.BottomLine {
  position: absolute;
  bottom: 0;
  height: 2px;
  left: 0;
  width: 100%;
  background: var(--blue);
  transform: scaleX(0);
}
.SkeletonCard,
.Card {
  width: 148px;
  padding-left: var(--pt1);
  padding-right: var(--pt1);
  padding-bottom: var(--pt2);
  display: inline-block;
  position: relative;
}
.Card,
.Card:hover {
  color: inherit;
  text-decoration: inherit;
}
.Card:hover,
.Card:focus {
  background: rgba(0, 0, 0, 0.1);
}
.Card:hover .BottomLine,
.Card:focus .BottomLine {
  transform: scaleX(1);
  transition: transform 0.35s;
}
.Title {
  margin: 0;
  margin-top: var(--pt1) !important;
}
.Creator {
  margin: 0;
}
.CoverWrapper {
  height: 200px;
  display: flex;
  justify-content: center;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .SkeletonCard,
  .Card {
    width: 160px;
  }
  .CoverWrapper {
    height: 200px;
  }
}

/* Large devices (desktops, 1200px and up) */
@media (min-width: 1200px) {
  .SkeletonCard,
  .Card {
    width: 208px;
  }
  .CoverWrapper {
    height: 288px;
  }
  .Title {
    margin-top: var(--pt2) !important;
  }
}
