.icon {
  /* width: var(--pt2); */
  /* height: 18px; */
  width: 20px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.line {
  width: 100%;
  height: 2px;
  background-color: var(--mine-shaft);
  margin: auto;
  transition-duration: 0.3s;
}

.line:nth-child(2) {
  transform: scale(0.75);
}
@media (hover: hover) {
  .trigger:hover .line:not(:nth-child(2)),
  .trigger:focus .line:not(:nth-child(2)) {
    animation: fullWidth 0.4s cubic-bezier(0.3, 1, 0.32, 1.3);
  }

  .trigger:hover .line:nth-child(2),
  .trigger:focus .line:nth-child(2) {
    transform: scale(1);
    animation: fullWidth 0.4s cubic-bezier(0.3, 1, 0.32, 2);
  }
}
@keyframes fullWidth {
  from {
    transform: scale(0.75);
  }
  to {
    transform: scale(1);
  }
}
