.login {
  max-width: 600px;
  padding: 0 var(--pt2);
  height: 100%;
}

.login button {
  margin-top: var(--pt5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login img {
  margin-left: var(--pt1);
}
.login p {
  margin-top: var(--pt3);
}

.inline {
  display: inline-block;
  margin-right: var(--pt2);
  margin-top: var(--pt2);
}

@media (min-width: 576px) {
  .login {
    padding: var(--pt2) var(--pt6) var(--pt4) var(--pt6);
  }
}
