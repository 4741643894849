.button {
  background-color: transparent;
  font-family: var(--body-font-medium);
  transition-duration: 0.35s;
  display: inline-block;
}

.button:hover {
  cursor: pointer;
}

/* 
* button Types 
*
*/

/* Primary */
.primary {
  background-color: var(--blue);
  color: var(--white);
  display: block;
  width: 100%;
  line-height: 24px;
}

.primary:hover {
  background-color: var(--mine-shaft);
  color: var(--white);
}

.primary.disabled {
  background-color: var(--concrete);
  outline: 2px solid var(--iron);
  color: var(--dove);
}

/* Secondary */

.secondary {
  color: var(--blue);
  outline: 2px solid var(--blue);
  line-height: 23px;
}

.secondary:hover {
  outline: 2px solid var(--mine-shaft);
  color: var(--mine-shaft);
}

.secondary.disabled {
  outline: 2px solid var(--concrete);
  color: var(--dove);
}

/* 
* button Sizes 
*
*/

/* primary */
.primary.large {
  font-size: 20px;
  padding-top: var(--pt2);
  padding-bottom: var(--pt2);
}

.primary.medium {
  font-size: 17px;
  padding: var(--pt1) 0;
}

.primary.small {
  font-size: 13px;
  padding: var(--pt05) 0;
}

/* secondary */
.secondary.large {
  font-size: 20px;
  padding: var(--pt2) var(--pt10);
}

.secondary.medium {
  font-size: 17px;
  padding: var(--pt1) var(--pt6);
}

.secondary.small {
  font-size: 13px;
  padding: var(--pt05) var(--pt4);
}

/* 
* Focused
*
*/

.primary:focus {
  background-color: var(--mine-shaft);
  color: var(--white);
  outline: none;
}

.secondary:focus {
  outline: 2px solid var(--mine-shaft);
  color: var(--mine-shaft);
}

/* 
* Disabled 
*/

.button.disabled {
  pointer-events: none;
  cursor: default;
}

/* 
* Skeleton
*/

.skeleton {
  position: relative;
}

@media (max-width: 414px) {
  .secondary.large {
    padding: var(--pt2) var(--pt1);
  }
}
