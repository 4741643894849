.filterButton {
  display: flex;
  align-items: center;
  padding: var(--pt1) var(--pt3) !important;
  white-space: nowrap;
  width: 100%;
  justify-content: center;
  background-color: var(--white);
}

.filterButton i {
  margin-right: var(--pt1);
  margin-bottom: 2px;
}
