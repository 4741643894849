.container {
  position: relative;
}

.container__open {
}

.input_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  padding: var(--pt2) var(--pt2) 0 0;
  border-bottom: 1px solid var(--iron);
  /* position fix hack for mobile suggester scroll */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: var(--white);
}

.input_wrap .arrow,
.input_wrap .clear {
  /* override dynamic style */
  min-width: var(--pt7);
  height: var(--pt7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.input_wrap .clear {
  position: absolute;
  right: 0;
  top: 15px;
  bottom: 0;
  transform: scale(0);
  transition: transform 0.3s;
  cursor: pointer;
}

.clear.visible {
  transform: scale(1);
  transition-timing-function: cubic-bezier(0.38, 1.95, 0.4, 0.4);
}

.input_wrap .arrow path,
.input_wrap .clear path {
  fill: var(--blue);
}

.input {
  width: 100%;
  height: var(--pt4);
  font-size: 17px;
  line-height: 26px;
  font-family: var(--body-font);
  border: none;
  padding: 0 var(--pt5) 0 0;

  /* remove border on iphone */
  border-radius: 0;
  -webkit-appearance: none;
}

.input__open {
  border-bottom: none;
}

.input__focused {
  border-bottom: 1px solid var(--mine-shaft);
}

.suggestions_container {
  display: none;
  position: relative;
  z-index: 2;
}

.suggestions_container__open {
  display: block;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: white;
  border-top: none;
  height: calc(100vh - 50px);
  overflow-y: auto;
  padding: var(--pt9) 0 var(--pt2) 0;
  box-shadow: var(--shadow-effect);
}

.suggestions_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestion {
  cursor: pointer;
  padding: var(--pt1) var(--pt2);
}

.suggestion__highlighted {
  background-color: var(--iron);
}

.suggestion .match {
  font-family: var(--body-font);
}

/* Skeleton */
.skeleton .suggestion {
  max-width: 400px;
}

/* history */
.history {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--pt1);
}

.title,
.clear {
  padding: var(--pt1);
}

.title {
}

.clear {
}

@media (min-width: 992px) {
  .input {
    height: 100%;
    /* The right padding has to be pt2 (for base) + 2 times pt2 for the icon */
    padding: var(--pt2) calc(var(--pt2) * 3) var(--pt2) var(--pt2);
    border: 1px solid var(--gray);
  }

  .input__focused {
    border: 1px solid var(--blue);
    outline: 1px solid var(--blue);
  }

  .input_wrap {
    height: var(--pt7);
    padding: 0;
    border: none;

    position: relative;
    z-index: 3;
    top: auto;
    left: auto;
  }

  .input_wrap .arrow {
    display: none;
  }

  .suggestions_container__open {
    top: calc(100% - 1px);

    border: 1px solid var(--gray);
    border-top: none;

    height: auto;
    max-height: 50vh;
    padding: 0;
  }

  .suggestion:first-child {
    padding: var(--pt2) var(--pt2) var(--pt1) var(--pt2);
  }

  .suggestion:last-child {
    padding: var(--pt1) var(--pt2) var(--pt2) var(--pt2);
  }

  /* only one suggestion in list*/
  .suggestion:only-child {
    padding: var(--pt2);
  }
  .input_wrap .clear {
    top: 0px;
  }
}
