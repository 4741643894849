.cover_image_skeleton {
  display: block;
  width: var(--cover_width_material_card);
  height: var(--cover_height_material_card);
  margin-inline: auto;

  border: 1px solid var(--concrete);
  outline: 0;
}

.link_style_colors {
  /* Add underline colors */

  --underline-middle-hover-color: var(--link-background);
}

.base_link_style {
  text-decoration: none;
  flex-grow: 1;
  /* -- */
  cursor: pointer;
  width: 100%;
  /* Add underline colors */
  --underline-font-hover-color: var(--mine-shaft);
}

/** ___________________ */
/**imageLeft material card*/
.textInformation {
  padding: var(--pt3) var(--pt6) var(--pt3) var(--pt2);
}

.container {
  width: 100%;
}

.article + .article > div > div {
  border-top: none;
}

.image {
  padding: var(--pt1) var(--pt6);
  display: flex;
  align-items: center;
}

.image > img {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 576px) {
  .image {
    padding: var(--pt1) var(--pt2);
  }

  .textInformation {
    padding: var(--pt3) var(--pt2) var(--pt3);
  }
}

/* skeleton */

.skeleton {
  pointer-events: none;
}

.skeleton div {
  width: 100%;
}

.skeleton img {
  background-color: var(--concrete);
  box-shadow: none;
  border: none;
  filter: none;
  height: calc(var(--pt16) * 2);
}
