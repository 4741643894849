.input {
  height: var(--pt7);
  width: 100%;
  border: 1px solid var(--gray);
  padding: 0 var(--pt2);

  font-size: 17px;
  line-height: 26px;
  font-family: var(--body-font);

  /* remove border-radius on iphone */
  border-radius: 0;
  -webkit-appearance: none;
}

.input:focus {
  border: 1px solid var(--blue);
  outline: 1px solid var(--blue);
}

.input.error {
  border: 1px solid var(--error);
  outline: 1px solid var(--error);
}

.input.readOnly {
  background-color: var(--concrete);
  color: var(--gray);
  -webkit-text-fill-color: var(--gray);
  cursor: not-allowed;
}

.input:disabled,
.input:read-only {
  color: var(--mine-shaft);
  -webkit-text-fill-color: var(--mine-shaft);
}

.skeleton {
  position: relative;
  border: none;
}
