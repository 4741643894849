.item {
  display: grid;
  grid-template-columns: 1fr 100fr 1fr;
  gap: var(--pt05);
  padding: 0 var(--pt2) var(--pt1) var(--pt2);
}

.item .checkbox {
  position: relative;
  top: var(--pt05);
}

.checkbox {
  margin-right: var(--pt1);
}

.helptxtlink {
  padding: 0 var(--pt1) var(--pt2) var(--pt1);
}

.facettitle {
  font-family: var(--body-font-semibold);
}

.facettext {
  cursor: pointer;
}

.score {
  text-align: right;
  position: relative;
  top: 2px;
}

.countContainer {
  display: flex;
  padding: 0 0 0 var(--pt2);
  font-family: var(--body-font-semibold);
}

.count {
  color: var(--white);
  background-color: var(--blue);
  padding: 0 var(--pt1) 0 var(--pt1);
  margin-left: var(--pt1);
  max-height: 22px;
  font-family: var(--body-font-semibold);
}

.showmorelink {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--pt1) 0 var(--pt2) 0;
}

.skeleton {
  position: relative;
  margin-bottom: var(--pt05);
}

.accordionContainer {
  width: 100%;
  border-bottom: 1px solid var(--iron);
}

.accordionContainer li:last-child {
  margin-bottom: var(--pt2);
}

.itemborder {
  border: 1px solid var(--iron);
  border-top: 0;
  border-bottom: 0;
}
