/**
* Stylesheet for global reusable styles
*
*/

@import "./bootstrap.override.css";
@import "./swiper-bundle.min.css";
@import "./colors.css";
@import "./fonts.css";
@import "./breakpoints.css";
@import "./spacing.css";
@import "./reset.css";

/* modal */
@import "./modal.css";

:root {
  --bounce-effect: cubic-bezier(0.38, 1.95, 0.4, 0.4);
  --shadow-effect: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.relative {
  position: relative;
}

/* content wrapper */
.container {
  height: 100%;
  --cols_per_card: var(--base_cols_per_card);
}

/* Align items center in review slider */
.alignCenter {
  align-items: center;
}

.inspiration-section-about h1 {
  margin-bottom: var(--pt1);
}

/* Hide visually, but appear in DOM */
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media (max-width: 375px) {
  .container,
  .container-fluid {
    --cols_per_card: 11;
  }
}

@media (max-width: 550px) {
  .container,
  .container-fluid {
    --cols_per_card: 9;
  }
}

@media (min-width: 768px) {
  .container,
  .container-fluid {
    --cols_per_card: 3.75;
  }
  .inspiration-section-about {
    max-width: 60%;
  }
}

@media (min-width: 992px) {
  .container,
  .container-fluid {
    --cols_per_card: 3;
  }
  .search-settings {
    box-shadow: unset;
  }
}

@media screen and (min-width: 1400px) {
  .container,
  .container-fluid {
    --cols_per_card: 3;
    max-width: var(--container_width);

    /* override grid spacing prop margin */
    margin: auto;
  }
}
