.wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--pt6);
}

/* .top is passed as className prop to Top component */
.top {
  padding-top: var(--pt3);
  padding-bottom: var(--pt3);
}

@media (min-width: 576px) {
  .top {
    padding-top: var(--pt5);
    padding-bottom: var(--pt3);
  }
}

.padding_inline {
  padding-inline: var(--pt2);
}

@media (min-width: 576px) {
  .padding_inline {
    padding-inline: var(--pt6);
  }
}

.subheader_text {
  padding-top: var(--pt1);
}

.search_bar {
  padding-top: var(--pt5);
  padding-bottom: var(--pt2);
}

.inputpadding input {
  padding-right: var(--pt4);
}

.agency_list {
  display: flex;
  flex-direction: column;
}

.branch_details {
  display: flex;
  flex-direction: column;
}

.highlighted_area {
  margin-top: var(--pt5);
}

.highlighted_area_children {
  display: flex;
  flex-direction: column;
  row-gap: var(--pt4);
}

.padding_block {
  padding-block: var(--pt3);
}
