.button {
  position: relative;
  height: 100%;
  padding: var(--pt2) var(--pt4);
  background-color: white;
  color: var(--blue);
  border: 1px solid var(--blue);
  font-family: var(--body-font);
  font-size: 20px;
  line-height: 22px;
  margin-left: var(--pt1);
  cursor: pointer;
  overflow: hidden;
}

.button span {
  color: var(--blue);
  position: relative;
  z-index: 2;
}

.button .fill {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--blue);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.75);
  transition-duration: 0.2s;
  z-index: 1;
  opacity: 0;
}

.button:hover > .fill,
.button:focus-visible > .fill {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.1);
}

.button:hover > span,
.button:focus-visible > span {
  color: white;
}

.input {
  border: none;
  font-size: 17px;
  font-family: var(--body-font);
  padding-left: var(--pt2);
  width: 100%;
  height: 100%;
  flex-shrink: 20;
  border: 1px solid var(--gray);
}
.input:focus {
  border: 1px solid var(--blue);
  outline: 1px solid var(--blue);
}

.form {
  display: flex;
}

.input_wrap {
  position: relative;
  line-height: 0;

  padding: 0;
  height: 56px;
  width: 100%;
  flex-shrink: 20;
}

.input_wrap .clear {
  position: absolute;
  right: var(--pt2);
  top: var(--pt05);
  transform: scale(0);
  transition: transform 0.3s;
  top: 18px;
  cursor: pointer;
}

.clear.visible {
  transform: scale(1);
  transition-timing-function: cubic-bezier(0.38, 1.95, 0.4, 0.4);
}

.input_wrap .clear path {
  fill: var(--blue);
}

@media (min-width: 576px) {
  .button {
    margin-left: var(--pt4);
  }
}
