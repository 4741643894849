.skiptomainlink {
  margin: -1px;
  padding: 1rem;
  top: 10px;
  left: 10px;
  background: white;
  width: auto;
  height: auto;
  border: 1px solid var(--mine-shaft);
  position: absolute;
  transform: translateX(-120%);
}

.skiptomainlink:focus-within {
  transform: translateX(0);
}
