.materialHeaderRow {
  display: grid;
  grid-column-gap: var(--pt3);
  grid-template-columns: 4fr 2fr 2fr 1fr;
  padding: var(--pt05) var(--pt1);
  border-bottom: 1px solid var(--iron);
}

.materialRow {
  display: grid;
  grid-column-gap: var(--pt3);
  grid-template-columns: 4fr 2fr 2fr 1fr;
  padding: var(--pt2) var(--pt1);
  max-height: 200px;
  transition: max-height 0.5s, padding 0.5s;
}

.materialRow_withGridCheckbox {
  grid-template-columns: 1fr 3fr 2fr 2fr 1fr;
  cursor: pointer;
}

.materialRow_withFlexCheckbox {
  display: flex;
  cursor: pointer;
  grid-column-gap: unset;
  padding-left: var(--pt1);
  padding-right: var(--pt1);
}

.materialRow_withGridCheckbox:focus-visible,
.materialRow_withGridCheckbox:hover,
.materialRow_withFlexCheckbox:focus-visible,
.materialRow_withFlexCheckbox:hover {
  background-color: var(--black-squeeze);
}

.materialRow_withFlexCheckbox .checkboxContainer {
  margin-right: var(--pt2);
}
.materialRow_withFlexCheckbox .materialInfo {
  margin-right: var(--pt4);
  flex: 1;
}

.materialRow_wrapper {
  width: 100%;
  border-bottom: 1px solid var(--iron);
}

.materialRow_animated {
  height: 0;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.materialRow_green {
  background-color: var(--success-light);
}

.materialRow_red {
  background-color: var(--error-light_temp);
}

.materialRow_mobile {
  position: relative;
  display: grid;
  grid-column-gap: var(--pt3);
  grid-template-columns: 1fr 3fr;
  padding: var(--pt2);
  cursor: pointer;
  border-top: 1px solid var(--iron);
  max-height: 260px;
  transition: max-height 0.8s, padding 0.8s;
}

.materialRow_mobile:focus-visible {
  outline: 2px solid var(--blue);
}

.materialRow_mobile:hover .arrowright,
.materialRow_mobile:focus-visible .arrowright {
  transform: translate(var(--pt05), 0);
}

.materialRow_mobile_animated {
  max-height: 0;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  border-top: none;
}

.materialInfo {
  display: flex;
}

.imageContainer {
  width: var(--pt7);
  margin-right: var(--pt5);
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.blackUnderline * {
  display: inline;
}

.blackUnderline {
  --underline-bottom-color: var(--mine-shaft);
  --underline-bottom-hover-color: var(--blue);
}

.isReady {
  display: block;
  color: var(--success);
}

.isWarning {
  display: block;
  color: var(--error_temp);
}

.inlineBlock {
  display: inline-block;
}

.arrowright_container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 9px; /* width of icon */
}

.arrowright {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: transform 0.3s ease-in-out;
}

.dynamicColumn {
  display: flex;
  flex-direction: column;
}

.dynamicColumnHorizontal {
  display: flex;
}

.dynamicContent {
  margin-top: var(--pt1);
}

.ornament {
  display: inline-block;
  margin-right: var(--pt1);
}

.uppercase::first-letter {
  text-transform: uppercase;
}

.renewedWrapper {
  display: flex;
  margin-bottom: var(--pt4);
  padding-left: var(--pt2);
  width: 100%;
  justify-content: center;
}

.renewedIcon {
  padding-top: 1px;
  padding-left: var(--pt1);
}

.textContainer {
  flex: 1;
}

.bookmarkActionButton {
  margin-right: var(--pt3);
  width: 100px;
}

.bookmarkMaterial {
  margin-top: var(--pt1);
}

.bookmarkMaterial span {
  display: block;
}

.bookmarkOrderButtonContainer {
  width: 100px;
  margin-right: var(--pt3);
}

.bookmarkOrderButtonContainer button[aria-disabled="false"] {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.bookmarkOrderedIcon {
  align-self: center;
  height: 100%;
  align-items: center;
}

.bookmarkOrderFailed {
  padding-top: var(--pt05);
  color: var(--error);
}

.bookmarkRemoveButton {
  align-items: normal;
}

.debtDynamicColumn {
  grid-column: 1;
}

.debtLibrary {
  grid-column: 2;
  overflow-wrap: anywhere;
}
.debtMaterial {
  grid-column: 3;
}

.debtRow {
  grid-auto-flow: column;
  grid-template-columns: 1.5fr 2.1fr 4fr 1fr;
  border-bottom: unset;
}

@media (hover: hover) {
  .materialRow_mobile:focus-visible,
  .materialRow_mobile:hover {
    background-color: var(--black-squeeze);
  }
}

@media (max-width: 992px) {
  .materialHeaderRow {
    display: none;
  }

  .materialRow_debt {
    display: flex;
  }
  .materialRow_debt:not(:first-of-type) {
    border-top: unset;
  }
}

@media (min-width: 993px) {
  .mobileText,
  i.ornament {
    display: none;
  }

  .dynamicColumn {
    flex-direction: column-reverse;
  }
}

@media (max-width: 767px) {
  .dynamicColumnHorizontal {
    display: none;
  }

  .materialRow_bookmark {
    grid-template-columns: 1fr;
  }

  .materialRow_withFlexCheckbox .materialInfo {
    margin-right: 0;
  }
}
