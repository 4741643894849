/* Body */

.body {
  font-size: 17px;
  line-height: 26px;
}
.body figure {
}

.body figure img {
  max-width: 100%;
}

.body figure {
  padding: var(--pt4) 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.body figcaption {
  font-size: 14px;
  line-height: 22px;
  padding: var(--pt1) 0 0 0;
}

.body strong {
  font-weight: normal;
  font-family: var(--body-font-semibold);
}

.body ul,
.body ol {
  padding-left: var(--pt5);
}

.body h1,
.body h2,
.body h3,
.body h4,
.body h5,
.body h6,
.body em[style*="bold"] {
  font-size: 17px;
  line-height: 26px;
  font-family: var(--body-font-semibold);
  font-weight: 400;
  padding-top: var(--pt3);
  padding-bottom: var(--pt1);
  font-style: normal;
}

.body p {
  padding-bottom: var(--pt3);
}
.body > *:first-child {
  padding-top: 0;
}

@media (min-width: 992px) {
  .body figure img {
    max-width: 100%;
  }
}
