.container {
  cursor: pointer;
  background-color: transparent;
  justify-content: center;
  display: flex;
  align-items: center;
}

.textWrapper {
  margin: 0 var(--pt1) 0 0;
}

.focusStyle:focus-visible {
  outline: 1px solid var(--blue);
}

.container:disabled {
  cursor: none;
  pointer-events: none;
}

button.container:disabled .textWrapper * {
  color: var(--dove);
  --underline-bottom-color: var(--dove);
}
