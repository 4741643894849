.orderNotPossible {
  color: var(--error);
  padding-top: var(--pt1);
  padding-bottom: var(--pt05);
}
.orderNotPossible * {
  color: var(--error);
}

.errorLabel {
  color: var(--error_temp);
}
