.background {
  margin-top: var(--pt8);
  color: white;
  padding-top: var(--pt8);
}

.background a {
  color: white !important;
}

.containerback {
  background-color: var(--blue);
}

.footerlink hr {
  background-color: white;
}

.spacer {
  height: var(--pt1);
}

.padder {
  padding-bottom: var(--pt5);
}

.footerlink {
  display: inline;
  --underline-font-color: var(--white);
  --underline-bottom-color: var(--white);
  --underline-bottom-hover-color: var(--white);
}

.about {
  padding-top: var(--pt5);
}

@media (min-width: 768px) {
  .about {
    padding-top: 0;
  }
}

/* Print styles */

@media print {
  .containerback {
    display: none;
  }
}
