.dropdownwrap {
}

/** hide tablet and down **/
@media (max-width: 992px) {
  .dropdownwrap {
    display: none;
  }
}

.dropdownitem {
  display: block;
  border-bottom: 1px solid var(--mine-shaft);
  padding: var(--pt1) var(--pt2) var(--pt1) var(--pt2);
  text-decoration: none;
  color: var(--mine-shaft);
  text-align: inherit;
  white-space: nowrap;
}

.dropdownitem a {
  text-decoration: none;
}

.dropdownitem:hover,
.dropdownitem:focus {
  background: linear-gradient(
    to right,
    var(--blue) var(--pt05),
    var(--concrete) var(--pt05)
  );
}

.dropdownwrap :global(.dropdown-toggle::after) {
  display: none;
}

.dropdownmenu {
  border: 1px solid var(--blue);
  background-color: var(--white);
  z-index: 1;
  /* bootstrap default styles override */
  border-radius: 0;
  padding: 0;
}

.dropdowntoggle:focus {
  border: 1px solid var(--blue);
  outline: 1px solid var(--blue);
}

.dropdowntoggle {
  position: relative;
  padding: var(--pt2) var(--pt5) var(--pt2) var(--pt2);
  border: 1px solid var(--gray);
  border-right: none;
  background-color: var(--white);
  min-width: 125px;
  height: 100%;
  text-align: left;
  cursor: pointer;
}

.dropdowntoggle[aria-expanded="true"] .dropdownicon img {
  transform: rotate(180deg);
}

.dropdownicon {
  transform: rotate(90deg);
  position: absolute;
  right: var(--pt2);
  top: 0;
  bottom: 0;
  margin: auto;
}

.dropdownicon img {
  transition: transform 0.2s linear;
}

.materials {
  display: flex;
  justify-content: space-between;
  align-items: center;

  overflow-y: hidden;
  margin: 0 -15px;
  white-space: nowrap;
  padding: var(--pt2) var(--pt2) var(--pt2) var(--pt2);
}

.materials button {
  margin: 0 var(--pt2) 0 0;
}

.materials button:last-child {
  margin: 0;
}

.filterButton {
  max-width: 124px;
  background-color: var(--white);
  margin: 0;
}

@media screen and (max-width: 768px) and (min-width: 991px) {
  .filterButton {
    display: none;
  }
}

.selectedItem {
  background-color: var(--blue);
  color: var(--white);
}
