.inputContainer {
  display: flex;
}

.suggesterContainer {
  margin-bottom: var(--pt2);
  flex-grow: 2;
  height: var(--pt5);
  margin-left: var(--pt1);
}

.select {
  white-space: nowrap;
}
.removeIcon {
  display: flex;
  margin-left: var(--pt2);
  padding-bottom: var(--pt2);
  cursor: pointer;
}

.dropdownwrap {
  width: fit-content;
  display: flex;
  margin-bottom: var(--pt2);
}

.addLine {
  box-shadow: inset 0 0 0 1px var(--dove);
  outline: 0;
  color: unset;
  display: flex;
  align-items: center;
  background-color: var(--white);
  padding: var(--pt05) var(--pt2) !important;
}

.addLine:hover,
.addLine:focus {
  outline: 0;
  box-shadow: inset 0 0 0 2px var(--blue);
}
.expandIcon {
  margin-left: var(--pt1);
  display: flex;
  align-items: center;
}
.dropdownitem {
  display: block;
  border-bottom: 1px solid var(--mine-shaft);
  padding: var(--pt1) var(--pt2) var(--pt1) var(--pt2);
  text-decoration: none;
  color: var(--mine-shaft);
  text-align: inherit;
  white-space: nowrap;
}

.dropdownitem a {
  text-decoration: none;
}

.dropdownitem:focus {
  background: linear-gradient(to right, var(--blue) 10px, var(--concrete) 10px);
}

.dropdownitem:hover {
  background: linear-gradient(to right, var(--blue) 10px, var(--concrete) 10px);
}

.dropdownwrap :global(.dropdown-toggle::after) {
  display: none;
}

.dropdownmenu {
  border: 1px solid var(--blue);
  background-color: var(--white);
  z-index: 5;
  /* bootstrap default styles override */
  border-radius: 0;
  padding: 0;
}

.dropdowntoggle {
  background-color: unset;
  min-height: 28px;
  display: flex;
  align-items: center;
  gap: var(--pt1);
  padding-inline: var(--pt2);
  color: var(--blue);
}

.dropdowntoggle:is(:focus-visible, :hover) {
  outline: 2px solid var(--blue);
}

.suggesterInput:is(:focus-visible, :hover) {
  outline: 0;
  box-shadow: inset 0 0 0 2px var(--blue);
}

.suggesterInput {
  /* The right padding has to be pt2 (for base) + 2 times pt2 for the icon */
  padding: var(--pt2) calc(var(--pt2) * 3) var(--pt2) var(--pt2);
  height: var(--pt5);
  border: none;
  outline: 0;
  box-shadow: inset 0 0 0 1px var(--dove);
  /* Text 3*/
  font-size: 14px;
  line-height: 22px;
}
.suggester {
  height: var(--pt5);
}

.inputTitle {
  margin-bottom: var(--pt2);
}

.trashAndSuggester {
  display: flex;
  width: 100%;
  margin-right: var(--pt2);
}

/* 640px up */
@media (min-width: 640px) {
  .inputContainer {
    display: flex;
  }

  .suggesterContainer {
    display: flex;
  }
}

/* 768px up */
@media (min-width: 768px) {
  .trashAndSuggester {
    max-width: 460px;
  }
}

@media (max-width: 768px) {
  .suggester input {
    font-size: 17px;
  }
}

/* 640px down */
@media (max-width: 640px) {
  .inputContainer {
    display: block;
  }

  .suggesterContainer {
    margin-left: 0;
    margin-top: var(--pt1);
    display: block;
  }

  .inputTitle {
    display: none;
  }
}

/* 463 px down */
@media (max-width: 463px) {
  .suggesterContainer {
    margin-left: 0;
    margin-top: var(--pt1);
  }

  .inputContainer {
    flex-wrap: wrap;
  }

  .inputTitle {
    display: none;
  }
}
