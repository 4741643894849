.row_wrapper {
  display: flex;
  flex-direction: row;
}

.result {
  margin-left: var(--pt1);
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.no_match_for_library_search {
  padding-top: var(--pt1);
}

.hint_for_no_search_results {
  padding-top: var(--pt1);
}

.pagination {
  padding-top: var(--pt3);
}
