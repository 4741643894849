/**
* Stylesheet for all font imports
*
*/

/* Google CDN import */
/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&family=IBM+Plex+Serif:wght@300;400;500&display=swap'); */

/**
* font-family: 'IBM Plex Sans', sans-serif;
*
*/

/* @font-face {
  font-family: "ibm_plex_sansbold";
  src: url("../../public/fonts/ibm-sans/ibmplexsans-bold-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/ibm-sans/ibmplexsans-bold-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "ibm_plex_sanssemibold";
  src: url("../../public/fonts/IBM-Plex-Sans/woff2/IBMPlexSans-SemiBold.woff2")
      format("woff2"),
    url("../../public/fonts/IBM-Plex-Sans/woff/IBMPlexSans-SemiBold.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ibm_plex_sansmedium";
  src: url("../../public/fonts/IBM-Plex-Sans/woff2/IBMPlexSans-Medium.woff2")
      format("woff2"),
    url("../../public/fonts//IBM-Plex-Sans/woff/IBMPlexSans-Medium.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ibm_plex_sansregular";
  src: url("../../public/fonts/IBM-Plex-Sans/woff2/IBMPlexSans-Regular.woff2")
      format("woff2"),
    url("../../public/fonts/IBM-Plex-Sans/woff/IBMPlexSans-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "ibm_plex_sanslight";
  src: url("../../public/fonts/IBM-Plex-Sans/woff2/IBMPlexSans-Light.woff2")
      format("woff2"),
    url("../../public/fonts/IBM-Plex-Sans/woff/IBMPlexSans-Light.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: "ibm_plex_sansextralight";
  src: url("../../public/fonts/IBM-Plex-Sans/woff2/IBMPlexSans-ExtraLight.woff2")
      format("woff2"),
    url("../../public/fonts/IBM-Plex-Sans/woff/IBMPlexSans-ExtraLight.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: "ibm_plex_sansthin";
  src: url("../../public/fonts/IBM-Plex-Sans/woff2/IBMPlexSans-Thin.woff2")
      format("woff2"),
    url("../../public/fonts/IBM-Plex-Sans/woff/IBMPlexSans-Thin.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
} */

/**
* font-family: 'IBM Plex Serif', serif;
*
*/

/* @font-face {
  font-family: "ibm_plex_serifbold";
  src: url("../../public/fonts/IBM-Plex-Serif/woff2/IBMPlexSerif-Bold.woff2")
      format("woff2"),
    url("../../public/fonts/IBM-Plex-Serif/woff/IBMPlexSerif-Bold.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: "ibm_plex_serifsemibold";
  src: url("../../public/fonts/IBM-Plex-Serif/woff2/IBMPlexSerif-SemiBold.woff2")
      format("woff2"),
    url("../../public/fonts/IBM-Plex-Serif/woff/IBMPlexSerif-SemiBold.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "ibm_plex_serifmedium";
  src: url("../../public/fonts/IBM-Plex-Serif/woff2/IBMPlexSerif-Medium.woff2")
      format("woff2"),
    url("../../public/fonts/IBM-Plex-Serif/woff/IBMPlexSerif-Medium.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ibm_plex_serifregular";
  src: url("../../public/fonts/IBM-Plex-Serif/woff2/IBMPlexSerif-Regular.woff2")
      format("woff2"),
    url("../../public/fonts/IBM-Plex-Serif/woff/IBMPlexSerif-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ibm_plex_seriflight";
  src: url("../../public/fonts/IBM-Plex-Serif/woff2/IBMPlexSerif-Light.woff2")
      format("woff2"),
    url("../../public/fonts/IBM-Plex-Serif/woff/IBMPlexSerif-Light.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "ibm_plex_serifextralight";
  src: url("../../public/fonts/IBM-Plex-Serif/woff2/IBMPlexSerif-ExtraLight.woff2")
      format("woff2"),
    url("../../public/fonts/IBM-Plex-Serif/woff/IBMPlexSerif-ExtraLight.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: "ibm_plex_serifthin";
  src: url("../../public/fonts/IBM-Plex-Serif/woff2/IBMPlexSerif-Thin.woff2")
      format("woff2"),
    url("../../public/fonts/IBM-Plex-Serif/woff/IBMPlexSerif-Thin.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "ibm_plex_mono";
  src: url("../../public/fonts/IBM-Plex-Mono/woff2/IBMPlexMono-Regular.woff2")
      format("woff2"),
    url("../../public/fonts/IBM-Plex-Mono/woff/IBMPlexMono-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  /*
*  Fonts
*
*/

  /**
  * Title Font (Serif)
  * 
  */

  /* 400 */
  --title-font: "ibm_plex_serifregular";

  /* 100 */
  /* --title-font-thin: "ibm_plex_serifthin"; */
  /* 200 */
  /* --title-font-xlight: "ibm_plex_serifextralight"; */
  /* 300 */
  --title-font-light: "ibm_plex_seriflight";
  /* 500 */
  --title-font-medium: "ibm_plex_serifmedium";
  /* 600 */
  /* --title-font-semibold: "ibm_plex_serifsemibold"; */
  /* 700 */
  /* --title-font-bold: "ibm_plex_serifsemibold"; */

  /**
  * Body Font (Sans Serif)
  * 
  */

  /* 400 */
  --body-font: "ibm_plex_sansregular";

  /* 100 */
  /* --body-font-thin: "ibm_plex_sansthin"; */
  /* 200 */
  /* --body-font-xlight: "ibm_plex_sansextralight"; */
  /* 300 */
  /* --body-font-light: "ibm_plex_sanslight"; */
  /* 500 */
  --body-font-medium: "ibm_plex_sansmedium";
  /* 600 */
  --body-font-semibold: "ibm_plex_sanssemibold";
  /* 700 */
  /* --body-font-bold: "ibm_plex_sansbold"; */
}
