.periodicaform {
  max-width: 600px;
  padding: var(--pt2);

  height: 100%;

  /* scroll in modal */
  overflow-y: auto;
}

.invalid input {
  border: solid 1px var(--error);
  outline: solid 1px var(--error);
}

.errormessage {
  color: var(--error);
}
.bottom {
  margin-top: var(--pt8);
}
.periodicaform input {
  margin-top: var(--pt1);
}
.periodicaform button {
  margin-top: var(--pt1);
}
.periodicaform .required {
  display: inline-block;
  color: var(--error);
}
.requiredtext {
  margin-top: var(--pt2);
}
.periodicaform form label {
  margin-top: var(--pt3);
}
.accordion {
  /* margin-top: var(--pt4); */
  margin-top: var(--pt6);
}
.accordion > div {
  /* border: 0 !important; */
}
.accordioncontent div:first-child label {
  margin-top: 0;
}
.accordioncontent div:last-child input {
  margin-bottom: var(--pt4);
}

@media (min-width: 576px) {
  .periodicaform {
    padding: var(--pt2) var(--pt6) var(--pt4) var(--pt6);
  }

  .field {
    padding-top: var(--pt2);
  }
}
