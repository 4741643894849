.subject {
  height: var(--pt5);
  display: flex;
  align-items: center;
}

.text {
  width: calc(100% - 40px);

  display: flex;
  flex-direction: column;
  /* padding-left: var(--pt2); */

  overflow: hidden;
}

.value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
