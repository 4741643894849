.dialogForPublicationYear {
  padding: var(--pt2);
  width: 600px;
  display: grid;
  grid-template-areas:
    "header close"
    "lowerRange upperRange";
  gap: var(--pt2);
}

.dialog_header {
  grid-area: header;
  padding: var(--pt1);
}

.dialog_close_button {
  grid-area: close;
  display: flex;
  flex-direction: row-reverse;
}

.lowerRange {
  grid-area: lowerRange;
}

.upperRange {
  grid-area: upperRange;
}
