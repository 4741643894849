.row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--pt2) var(--pt2) var(--pt2) 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.group.disabled {
  pointer-events: none;
}

.boxOutline:focus-within {
  outline: 1px solid var(--blue);
}

.row:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}
.row > .dot {
  position: relative;
  height: 18px;
  width: 18px;
}
.row.selected > .dot {
  border-color: var(--blue);
}

.checkmark {
  display: none;
}

.row.selected > .checkmark {
  color: white;
  display: block;
  position: absolute;
  left: 2px;
  top: 10px;
  transform: scale(0.8);
  font-weight: bolder;
}

.row .content {
  width: 100%;
}

/* Radio button animation */
.row > .dot::before,
.row > .dot::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.row > .dot::before {
  background: var(--blue);
  transform: scale(0);
  transition: transform 0.4s;
}
.row.selected > .dot::before {
  transform: scale(0.7);
}

.row.selected > .checkdot::before {
  transform: scale(0.9);
}
.row > .dot::after {
  border: 1px solid var(--gray);
  box-sizing: border-box;
}
.row.selected > .dot::after {
  border-color: var(--blue);
}

.divider {
  background-color: var(--iron);
  padding: 0;
  height: var(--pt1);
  flex-shrink: 0;
  align-self: stretch;
  border: 0;
}

.label {
  display: none;
}

.disabledrow {
  pointer-events: none;
}
.disabledrow {
  color: var(--gray) !important;
}
.disabledrow .dot::after {
  border-color: var(--gray) !important;
}
.disabledrow .dot::before {
  background: var(--gray) !important;
}

.content {
  margin-left: var(--pt1);
}
