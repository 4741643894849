.search {
  position: relative;
}

.icon {
  position: absolute;
  top: 0;
  bottom: 2px;
  right: var(--pt2);
  margin: auto;
}
