.popover {
  border-radius: 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.tooltipContainer {
  padding: var(--pt1) var(--pt1);
  max-width: 240px;
}

.tooltipCursor {
  cursor: pointer;
}

.tooltipWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.border_animation {
  border-bottom: 1px solid transparent;
}

.border_animation:focus {
  border-bottom: 1px solid var(--blue);
}
