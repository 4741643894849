.container {
  padding: var(--pt5) 0;
}

.container > * {
  padding: 0;
}

.container > * + * {
  margin-top: var(--pt5);
}

.formLabel,
.errorLabel {
  margin-bottom: var(--pt1);
}

.closedreason {
  padding: var(--pt3);
  margin: var(--pt2) -48px 0 -48px;
  background-color: var(--warning);
}

.errorLabel {
  color: var(--error_temp);
}

.formSubmit {
  margin-top: var(--pt2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.chooseOrderAgain {
  color: var(--error_temp);
  background-image: linear-gradient(rgba(255, 0, 0, 0), rgba(255, 0, 0, 0)),
    linear-gradient(rgb(186, 77, 87), rgb(186, 77, 87)),
    linear-gradient(rgba(255, 0, 0, 0), rgba(255, 0, 0, 0));
}

.goToOrderHistory {
  padding-top: var(--pt6);
}
