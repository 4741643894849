.arrow {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
}

.arrow.flip .trapezoid {
  transition: transform 0.3s ease;
}

.arrow.flip .trapezoid:nth-child(1) {
  transform: rotate(45deg);
}
.arrow.flip .trapezoid:nth-child(2) {
  transform: translateY(6px) rotate(135deg);
}
.arrow.flip .trapezoid:nth-child(3) {
  transform: translateX(8px) rotate(45deg);
}
.arrow.flip .trapezoid:nth-child(4) {
  transform: translateX(8px) translateY(6px) rotate(135deg);
}

.trapezoid {
  position: absolute;
  width: 4px;

  border-bottom: 12px solid var(--blue);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  top: 0px;
  left: 4px;
  transition: transform 0.2s ease;
  shape-rendering: geometricPrecision;
  image-rendering: optimizeQuality;
  text-rendering: geometricPrecision;
}

.trapezoid:nth-child(1) {
  transform: rotate(-45deg);
}
.trapezoid:nth-child(2) {
  transform: translateY(6px) rotate(225deg);
}
.trapezoid:nth-child(3) {
  transform: translateX(8px) rotate(-45deg);
}
.trapezoid:nth-child(4) {
  transform: translateX(8px) translateY(6px) rotate(225deg);
}
