.underlineContainer__colors {
  --underline-font-color: var(--error_temp);
  --underline-font-hover-color: var(--mine-shaft);
  --underline-bottom-color: var(--mine-shaft);
  --underline-bottom-hover-color: var(--mine-shaft);
  display: contents;
}

.display_inline {
  display: inline;
}

/* Mobile first */
div.redBorder {
  padding: var(--pt2) var(--pt2);
  color: var(--error_temp);
  background: var(--error-light_temp);
  word-break: normal;
  white-space: normal;
  font-size: 17px;
}

div.redBorder span {
  padding: 0;
  font-family: var(--body-font-semibold);
}

/* Bigger than mobile */
@media (min-width: 576px) {
  div.redBorder {
    padding: var(--pt2) var(--pt6);
  }
}
