.container {
  padding-top: var(--pt1);
}

.buttonContainer {
  display: flex;
  margin-top: var(--pt05);
  flex-direction: row;
  gap: var(--pt4);
}

.icon > i {
  display: flex;
  align-items: center;
}
