.h-border-bottom,
.f-border-bottom {
  display: inline-block;
  position: relative;
}
.h-border-bottom::after,
.f-border-bottom::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  left: 0;
  border-bottom: solid 1px var(--blue);
  transform: scaleX(0);
}
:is(
    .on-hover,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):hover.h-border-bottom:after,
:is(
    .on-hover,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):hover
  .h-border-bottom:after,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible.f-border-bottom:after,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible
  .f-border-bottom:after,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible
  ~ .f-border-bottom:after {
  transform: scaleX(1);
  transition: transform 0.4s;
}

/* Translation */
.h-translate-right,
.f-translate-right {
  transition: transform 0.3s;
}
:is(
    .on-hover,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):hover.h-translate-right,
:is(
    .on-hover,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):hover
  .h-translate-right,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible.f-translate-right,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus
  .f-translate-right,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible
  ~ .f-translate-right {
  transform: translateX(var(--pt1));
}

:is(
    .on-hover,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):hover.h-color-blue,
:is(
    .on-hover,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):hover
  .h-color-blue,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible.f-color-blue,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible
  .f-color-blue,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible
  ~ .f-color-blue {
  color: var(--blue);
}

:is(
    .on-hover,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):hover.h-outline,
:is(
    .on-hover,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):hover
  .h-outline,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible.f-outline,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible
  .f-outline,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible
  ~ .f-outline {
  outline: solid 1px var(--blue);
  border-color: transparent !important;
}

/* Bounce */
.h-bounce-right,
.f-bounce-right,
.h-bounce-left,
.f-bounce-left {
  transition: transform 0.3s;
}

:is(
    .on-hover,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):hover.h-bounce-right,
:is(
    .on-hover,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):hover
  .h-bounce-right,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible.f-bounce-right,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible
  .f-bounce-right,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible
  ~ .f-bounce-right {
  animation: bounceRight 0.4s cubic-bezier(0.3, 1, 0.32, 1.3);
}

:is(
    .on-hover,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):hover.h-bounce-left,
:is(
    .on-hover,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):hover
  .h-bounce-left,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible.f-bounce-left,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus
  .f-bounce-left,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible
  ~ .f-bounce-left {
  animation: bounceRight 0.4s cubic-bezier(0.3, 1, 0.32, 1.3);
}

@keyframes bounceLeft {
  from {
    transform: translate(-10px, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes bounceRight {
  from {
    transform: translate(10px, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

/* elastic */
:is(
    .on-hover,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):hover.h-elastic,
:is(
    .on-hover,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):hover
  .h-elastic,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible.f-elastic,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible
  .f-elastic,
:is(
    .on-focus,
    .underlineContainer,
    .underlineContainer__only_internal_animations
  ):focus-visible
  ~ .f-elastic {
  animation: elastic 0.4s cubic-bezier(0.3, 1, 0.32, 1.3);
}

@keyframes elastic {
  20% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* ================================================================= */
/* ================================================================= */
/* CONTROL UNDERLINE */

/* Exceptional behaviour */
/* Additional background hover effects */

/* Positioning */
.underlineContainer.top_line_false {
  --underline-top-hover-color: var(--transparent);
}
.underlineContainer.top_line_keep_false {
  --underline-top-color: var(--transparent);
}
.underlineContainer.bottom_line_false {
  --underline-bottom-hover-color: var(--transparent);
}
.underlineContainer.bottom_line_keep_false {
  --underline-bottom-color: var(--transparent);
}

/* Turn off link */
.underlineContainer.link_disabled {
  pointer-events: none;
}

/* Turn off animation */
.underlineContainer.animation_disabled {
  --underline-top-hover-color: var(--transparent);
  --underline-bottom-hover-color: var(--transparent);
}
/* ----------- */
/* ----------- */

/* Set color of background image, when colors are overridden */
/*   as used in etc. styles.underlineVarContainer (BlockedUserInformation) */
.underlineContainer {
  --underline-height: 1px;
  --underline-background-position: 50% 0%, 50% 100%, 50% 50%;
  --underline-background-size: 100% var(--underline-height),
    100% var(--underline-height), 100% 100%;
  --underline-background-hover-size_0pct: 0% var(--underline-height),
    0 var(--underline-height), 100% 100%;
  --underline-background-hover-size_100pct: 100% var(--underline-height),
    100% var(--underline-height), 100% 100%;

  --underline-background-image: linear-gradient(
      var(--underline-top-color),
      var(--underline-top-color)
    ),
    linear-gradient(
      var(--underline-bottom-color),
      var(--underline-bottom-color)
    ),
    linear-gradient(
      var(--underline-middle-color),
      var(--underline-middle-color)
    );
  --underline-hover-background-image: linear-gradient(
      var(--underline-top-hover-color),
      var(--underline-top-hover-color)
    ),
    linear-gradient(
      var(--underline-bottom-hover-color),
      var(--underline-bottom-hover-color)
    ),
    linear-gradient(
      var(--underline-middle-hover-color),
      var(--underline-middle-hover-color)
    );

  text-decoration: none;
  color: var(--underline-font-color);
  background-image: var(--underline-background-image);
  background-size: var(--underline-background-size);
  background-position: var(--underline-background-position);
  background-repeat: no-repeat;
  padding-bottom: 1px;
  cursor: pointer;
}

.underlineContainer:is(:hover, :focus-visible),
.underlineContainer__only_internal_animations:is(:hover, :focus-visible)
  .underlineContainer {
  animation: __underline 0.3s ease;
  color: var(--underline-font-hover-color);
  background-image: var(--underline-hover-background-image);
  background-size: var(--underline-background-hover-size_100pct);
  background-position: var(--underline-background-position);
}

@keyframes __underline {
  0% {
    background-size: var(--underline-background-hover-size_0pct);
  }
  100% {
    background-size: var(--underline-background-hover-size_100pct);
  }
}
/* ================================================================= */
